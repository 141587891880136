// libs
import { tw } from 'packages/lib/tw'
// types
// TODO:Fix import
// import { User } from 'apps/system/types/hasura'
// components
import { Avatar } from './Avatar'

const AvatarGroupWrapper = tw.div`flex -space-x-1 overflow-hidden`

interface AvatarGroupProps {
  data: any[]
  maxAmount?: number
}

export function AvatarGroup({ data, maxAmount = 5 }: AvatarGroupProps): JSX.Element {
  return (
    <AvatarGroupWrapper>
      {data.map((user, idx) => {
        if (idx + 1 <= maxAmount) {
          // eslint-disable-next-line react/no-array-index-key
          return <Avatar key={idx} name={user.name} />
        }
        return null
      })}
    </AvatarGroupWrapper>
  )
}
