// libs
import { tw } from 'packages/lib/tw'
// components
import { Avatar } from 'packages/ui/Avatar'

// views
const UserInfoContainer = tw.div`mt-auto flex bg-gray-700 p-4 items-center text-black`

const UserInfoWrapper = tw.div`ml-3`

const UserName = tw.div`select-none text-sm font-medium text-white`

const UserAction = tw.div`cursor-pointer text-xs font-medium text-gray-300 hover:text-gray-200`

export function UserInfo({ name = 'User Name' }): JSX.Element {
  return (
    <UserInfoContainer>
      <Avatar name="UN" />
      <UserInfoWrapper className="ml-3">
        <UserName>{name}</UserName>
        <UserAction>View profile</UserAction>
      </UserInfoWrapper>
    </UserInfoContainer>
  )
}
