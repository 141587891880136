// libs
import { useRouter } from 'next/router'
// components
import { Link } from 'ui/Link'
// views
import { LinkContainer, LinkTitle, Bubble } from './MenuItem.views'
// types
import { NavItem } from './MenuItem.types'

interface MenuItemProps {
  item: NavItem
}

export function MenuItem({ item }: MenuItemProps): JSX.Element {
  const { pathname } = useRouter()

  const isActive = item.href !== '/' ? pathname.includes(item.href) : false

  return (
    <Link href={item.href}>
      <LinkContainer $isActive={isActive}>
        <item.icon isActive={isActive} />
        <LinkTitle $isActive={isActive}>{item.name}</LinkTitle>
        {item.count && <Bubble $isActive={isActive}>{item.count}</Bubble>}
      </LinkContainer>
    </Link>
  )
}
