import { useUserData } from '@nhost/nextjs'

export function useRedirectToUrl() {
  const userData = useUserData()
  let url

  switch (userData?.defaultRole) {
    // case 'developer':
    // case 'api':
    //   url = process.env.NEXT_PUBLIC_README_URL
    //   break

    default:
      url = '/dashboard'
      break
  }

  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}${url}`
}
