// libs
import { tw } from 'lib/tw'
// assets
import LogoBigSVG from '../../../../public/logo.svg'

export const Logo = tw(LogoBigSVG)`object-fill fill-current text-white`

export const SidebarContainer = tw.div``

export const SidebarWrapper = tw.div<{ $expanded: boolean }>`
  ${({ $expanded }) => ($expanded ? 'left-0' : 'left-[-240px]')}
  fixed inset-y-0 z-30 flex h-screen w-[240px] flex-col bg-gray-800 text-white duration-700 pt-2
  ease-in-out
`

export const SidebarSwipeTrigger = tw.div`fixed z-20 h-screen w-[20px] lg:hidden`

export const NavigationMenuWrapper = tw.div`grid space-y-1 overflow-hidden px-2`

export const IconWrapper = tw.div`absolute left-[250px] cursor-pointer lg:hidden top-2 group`
