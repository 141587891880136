// libs
import { tw } from 'lib/tw'
import { ChangeEvent } from 'react'

// views
const SearchbarContainer = tw.div`
  flex w-full flex-1 items-center justify-between relative max-w-2xl text-gray-400
  focus-within:text-gray-500 max-w-lg w-full xl:max-w-xl
`

const Input = tw.input`
  block w-full pl-12 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm
  placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 text-sm
  focus:ring-primary-600 focus:border-primary-600
`

const IconWrapper = tw.div`
  pointer-events-none absolute inset-y-0 left-0 flex items-center
  justify-center pl-4
`

// components
function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  )
}

interface SearchBarProps {
  defaultValue?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export function SearchBar({ onChange, defaultValue }: SearchBarProps) {
  return (
    <SearchbarContainer>
      <Input
        id="search"
        type="search"
        onChange={onChange}
        placeholder="Search"
        defaultValue={defaultValue}
      />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </SearchbarContainer>
  )
}
