// libs
import { tw } from 'packages/lib/tw'
// components
import { Logo } from './Logo'

// views
const Nav = tw.nav`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`

const NavContainer = tw.div`
  w-full py-6 md-down:py-4 flex items-center justify-between border-primary-500 lg:border-none
`

const LogoContainer = tw.div`flex items-center`

export const Header = tw((props) => (
  <header {...props}>
    <Nav aria-label="Top">
      <NavContainer>
        <LogoContainer>
          <Logo.Link href="/" color="white" className="md-down:h-8 h-10" />
        </LogoContainer>
      </NavContainer>
    </Nav>
  </header>
))`bg-primary-500 shadow-md z-20`

export default Header
