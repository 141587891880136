// libs
import { useState } from 'react'
import { useRouter } from 'next/router'
// icons
import { Arrow } from 'ui/icons'
// components
import { Link } from 'ui/Link'
// views
import { LinkContainer, LinkTitle, Bubble, IconWrapper } from './MenuItem.views'
// types
import { NavItem } from './MenuItem.types'

interface MenuItemExpandableProps {
  item: NavItem
}

export function MenuItemExpandable({ item }: MenuItemExpandableProps): JSX.Element {
  const { pathname } = useRouter()

  const [expanded, setExpanded] = useState(false)

  const toggleMenu = () => setExpanded((prev) => !prev)

  const isActive = item.href !== '/' ? pathname.includes(item.href) : false
  const isActiveExpMenu = isActive && !expanded

  return (
    <>
      <LinkContainer $isActive={isActiveExpMenu} onClick={toggleMenu}>
        <item.icon isActive={isActiveExpMenu} />
        <LinkTitle $isActive={isActiveExpMenu}>{item.name}</LinkTitle>

        {item.count && <Bubble $isActive={isActiveExpMenu}>3</Bubble>}

        <IconWrapper $expanded={expanded}>
          <Arrow />
        </IconWrapper>
      </LinkContainer>

      {expanded &&
        item.subItems &&
        item.subItems.map((subItem) => (
          <Link key={subItem.name} href={subItem.href}>
            <LinkContainer $isSubItem $isActive={pathname === subItem.href}>
              <LinkTitle $isActive={pathname === subItem.href}>{subItem.name}</LinkTitle>
            </LinkContainer>
          </Link>
        ))}
    </>
  )
}
