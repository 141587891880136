/* eslint-disable jsx-a11y/anchor-is-valid */
import Link, { LinkProps } from 'next/link'
import { TwComponent, TwProps, tw } from 'packages/lib/tw'

import LogoSVG from 'public/logo.svg'
import React from 'react'
import { ScreenReader } from './ScreenReader'

export interface LogoProps extends TwProps {
  color?: 'primary' | 'white'
}

export type LogoLinkProps = LogoProps & LinkProps

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Logo = tw<LogoProps, { Link: TwComponent<LogoLinkProps> }>(LogoSVG)`
  object-fill fill-current

  ${({ color = 'primary' }) =>
    ({
      primary: `
      text-primary-500
    `,
      white: `
      text-white
    `,
    }[color])}
`

Logo.Link = tw<LogoLinkProps>(
  ({ className, color, ...props }) => (
    <Link {...props}>
      <a>
        <ScreenReader>Snap</ScreenReader>

        <Logo color={color} className={className} />
      </a>
    </Link>
  ),
  { displayName: 'Logo.Link' },
)``

export default Logo
