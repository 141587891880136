// libs
import { useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
// hooks
import { useClickOutside, useNavigationLinks } from 'hooks'
// icons
import { CloseIcon } from 'ui/icons'
// components
import { UserInfo } from './UserInfo'
import { SidebarMenuItem } from './MenuItem'
// views
import {
  IconWrapper,
  SidebarWrapper,
  SidebarContainer,
  SidebarSwipeTrigger,
  NavigationMenuWrapper,
} from './Sidebar.views'

// types
interface SidebarProps {
  expanded: boolean
  toggleSidebar: () => void
}

export function Sidebar({ expanded, toggleSidebar }: SidebarProps): JSX.Element {
  const ref = useRef(null)

  const navigation = useNavigationLinks()

  const sHandlers = useSwipeable({
    trackTouch: true,
    onSwipedRight: () => toggleSidebar(),
  })

  const eHandlers = useSwipeable({
    trackTouch: true,
    onSwipedLeft: () => toggleSidebar(),
  })

  useClickOutside(ref, () => expanded && toggleSidebar())

  return (
    <SidebarContainer ref={ref}>
      <SidebarSwipeTrigger {...(sHandlers as any)} />

      <SidebarWrapper $expanded={expanded} {...(eHandlers as any)}>
        <NavigationMenuWrapper>
          {navigation.map((item) => (
            <SidebarMenuItem key={item.href} item={item} />
          ))}
        </NavigationMenuWrapper>

        {expanded && (
          <IconWrapper aria-hidden="true" onClick={toggleSidebar}>
            <CloseIcon />
          </IconWrapper>
        )}

        <UserInfo />
      </SidebarWrapper>
    </SidebarContainer>
  )
}
