import { useEffect } from 'react'

export const useClearHash = () => {
  useEffect(() => {
    if (window.location.hash) {
      const newUrl = `${window.location.origin}${window.location.pathname}`

      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
    }
  }, [])
}
