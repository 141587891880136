// libs
import { tw } from 'packages/lib/tw'
import { ReactNode } from 'react'
// icons
import { HomeSolidIcon } from '../icons'
// components
import { Link } from '../Link'

// views
const BreadcrumbsContainer = tw.nav`
  px-2 flex items-center space-x-0 md:space-x-1 border-t border-gray-200 py-3 sm:px-4 lg:px-8
  lg:space-x-4 overflow-x-auto
`

const BreadcrumbWrapper = tw.div`flex items-center`
const LinkWrapper = tw.div`group flex items-center space-x-0 md:space-x-2`
const LinkName = tw.span`text-xs md:text-sm font-medium text-gray-500 group-hover:text-gray-700 lg:ml-4 whitespace-nowrap select-none`

// types
export type Breadcrumb = {
  name: string
  href?: string
  component?: ReactNode
  hideDivider?: boolean
}

interface BreadcrumbsProps {
  data: Breadcrumb[]
}

function Divider() {
  return (
    <svg
      className="text- h-4 w-4 flex-shrink-0 text-gray-300 md:h-5 md:w-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </svg>
  )
}

export function Breadcrumbs({ data }: BreadcrumbsProps): JSX.Element {
  return (
    <BreadcrumbsContainer>
      <Link href="/dashboard">
        <HomeSolidIcon />
      </Link>

      {data.map((breadcrumb) => (
        <BreadcrumbWrapper key={breadcrumb.name}>
          {!breadcrumb.hideDivider && <Divider />}
          <LinkWrapper>
            {breadcrumb.component}
            {!breadcrumb.component &&
              (breadcrumb.href ? (
                <Link href={breadcrumb.href}>
                  <LinkName>{breadcrumb.name}</LinkName>
                </Link>
              ) : (
                <LinkName>{breadcrumb.name}</LinkName>
              ))}
          </LinkWrapper>
        </BreadcrumbWrapper>
      ))}
    </BreadcrumbsContainer>
  )
}
