// libs
import Head from 'next/head'
import { tw } from 'lib/tw'
import { ReactNode, useState } from 'react'
// types
import { Breadcrumb } from 'ui/Breadcrumbs'
// components
import { Sidebar, PageHeader } from './components'

// views
const Main = tw.main`h-screen ml-0 overflow-hidden flex flex-col`

const Content = tw.div`overflow-hidden h-full`

const Backdrop = tw.div`fixed z-20 inset-0 bg-gray-900 opacity-50 lg:hidden`

// types
export interface LayoutProps {
  title?: string
  children: ReactNode
  breadcrumbs?: Breadcrumb[]
}

export function AuthenticatedLayout({
  title,
  children,
  breadcrumbs = [],
}: LayoutProps): JSX.Element {
  const [expanded, setExpanded] = useState(false)

  const toggleSidebar = () => setExpanded((prev) => !prev)

  return (
    <>
      <Head>
        <title>{title || 'Snap - Automated workflow solutions for the Proptech industry'}</title>
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
      </Head>

      <Sidebar expanded={expanded} toggleSidebar={toggleSidebar} />

      <Main>
        <PageHeader toggleSidebar={toggleSidebar} breadcrumbs={breadcrumbs} globalSearch={false} />
        <Content>{children}</Content>
      </Main>

      {expanded && <Backdrop />}
    </>
  )
}
