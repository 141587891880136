// libs
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useSignOut, useUserData } from '@nhost/react'
// hooks
import { useNavigationLinks } from 'hooks'
// icons
import { MenuIcon } from 'ui/icons'
// components
import { Link } from 'ui/Link'
import { Avatar } from 'ui/Avatar'
import { Dropdown } from 'ui/Dropdown'
import { SearchBar } from 'ui/SearchBar'
import { Breadcrumb, Breadcrumbs } from 'ui/Breadcrumbs'
import { Logo } from '../Sidebar/Sidebar.views'
// views
import {
  LinkTitle,
  IconWrapper,
  ItemsContent,
  LogoContainer,
  HeaderWrapper,
  HeaderContainer,
  HeaderNavigation,
  SearchbarContainer,
} from './Header.views'

// types
export interface PageHeaderProps {
  breadcrumbs: Breadcrumb[]
  toggleSidebar: () => void
  /** default value is true */
  globalSearch?: boolean
}

export function PageHeader({ toggleSidebar, breadcrumbs, ...props }: PageHeaderProps): JSX.Element {
  const router = useRouter()
  const user = useUserData()

  const navigation = useNavigationLinks()

  const userInitials = useMemo(
    () =>
      user?.displayName
        .split(' ')
        .map((n) => n[0])
        .join(''),
    [user?.displayName],
  )

  const { signOut } = useSignOut()

  const handleSignOut = async () => {
    const { isSuccess } = await signOut()

    if (isSuccess) {
      await router.push({ pathname: '/sign-in' })
    }
  }

  const userMenu = [
    // TODO: Create settings page
    // {
    //   label: 'Settings',
    //   clickHandler: () => {},
    // },
    { label: 'Sign Out', clickHandler: handleSignOut },
  ]

  const globalSearch = props.globalSearch ?? true

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <LogoContainer>
          <Logo className="h-7 fill-primary-600" />
        </LogoContainer>

        <HeaderNavigation>
          {navigation.map((item) => {
            if (item.subItems) {
              const dropdownItems = item.subItems.map((navItem) => ({
                id: navItem.href,
                label: navItem.name,
                clickHandler: () => router.push(navItem.href),
              }))

              return (
                <Dropdown
                  key={item.name}
                  items={dropdownItems}
                  selected={router.pathname}
                  trigger={<LinkTitle>{item.name}</LinkTitle>}
                />
              )
            }

            return (
              <Link key={item.name} href={item.href}>
                <LinkTitle>{item.name}</LinkTitle>
              </Link>
            )
          })}
        </HeaderNavigation>

        {globalSearch && (
          <SearchbarContainer>
            <SearchBar onChange={() => {}} />
          </SearchbarContainer>
        )}

        <ItemsContent>
          {/* TODO: Only show if permanent notifications */}
          {/* <BellIcon /> */}
          <Dropdown
            items={userMenu}
            trigger={<Avatar name={userInitials || ''} preset="small" />}
          />
        </ItemsContent>

        <IconWrapper aria-hidden="true" onClick={toggleSidebar}>
          <MenuIcon />
        </IconWrapper>
      </HeaderWrapper>

      <Breadcrumbs data={breadcrumbs} />
    </HeaderContainer>
  )
}
