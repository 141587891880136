// libs
import { useUserData } from '@nhost/react'
// icons
import { HomeIcon, TemplateIcon } from 'ui/icons'
// types
import { NavItem } from '../layouts/Authenticated/components/Sidebar/MenuItem/MenuItem.types'

const navigation: NavItem[] = [
  { icon: HomeIcon, name: 'Dashboard', href: '/dashboard' },
  {
    icon: TemplateIcon,
    name: 'Admin Panel',
    href: '/admin',
    subItems: [
      { name: 'Users', href: '/admin/users' },
      { name: 'Companies', href: '/admin/companies' },
    ],
  },
]

export const useNavigationLinks = () => {
  const user = useUserData()

  const isAdmin = user?.roles.includes('admin')

  return navigation.filter((item) => {
    if (item.href !== '/admin') return true

    return item.href === '/admin' && isAdmin
  })
}
