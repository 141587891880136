export function Arrow(): JSX.Element {
  return (
    <svg
      className="h-5 w-5 transform text-gray-300 transition-colors duration-150 ease-in-out group-hover:text-white"
      viewBox="0 0 20 20"
    >
      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
    </svg>
  )
}
