// libs
import Head from 'next/head'
import { ReactNode } from 'react'
// components
import { Logo } from 'ui/Logo'
// views
import {
  FormTitle,
  FormContainer,
  BackgroundImg,
  LayoutContainer,
  FormDescription,
  BackgroundWrapper,
  FormInnerContainer,
} from './Auth.views'

// types
interface AuthLayoutProps {
  text: string
  title?: string
  children: ReactNode
  description?: string | ReactNode
}

export function AuthLayout({ text, title, description, children }: AuthLayoutProps): JSX.Element {
  return (
    <>
      <Head>
        <title>{title || 'Snap - Automated workflow solutions for the Proptech industry'}</title>
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
      </Head>

      <LayoutContainer>
        <FormContainer>
          <FormInnerContainer>
            <Logo.Link href="/" color="primary" className="h-10 md-down:h-8" />
            <FormTitle>{text}</FormTitle>
            {description && <FormDescription>{description}</FormDescription>}
            {children}
          </FormInnerContainer>
        </FormContainer>

        <BackgroundWrapper>
          <BackgroundImg
            layout="fill"
            alt="background-image"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          />
        </BackgroundWrapper>
      </LayoutContainer>
    </>
  )
}
