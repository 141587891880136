// libs
import { ReactNode } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export type DropdownItem = {
  id?: string
  label: string
  clickHandler(): void
}

interface DropdownProps {
  items: DropdownItem[]
  trigger: ReactNode
  selected?: string
}

export function Dropdown({ items, trigger, selected }: DropdownProps): JSX.Element {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="focus:outline-none">{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Content className="rounded bg-white py-1 drop-shadow-lg">
        {items.map((item) => {
          const isSelected = selected === item?.id

          return (
            <DropdownMenu.Item
              key={item.label}
              onClick={item.clickHandler}
              className={`${isSelected ? 'font-bold' : ''}
                focus:outline-none' cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none
              `}
            >
              {item.label}
            </DropdownMenu.Item>
          )
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
