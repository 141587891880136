// libs
import { ReactNode } from 'react'
// components
import NextLink from 'next/link'

interface LinkProps {
  href: string
  children?: ReactNode
}

export function Link({ href, children }: LinkProps): JSX.Element {
  return (
    <NextLink href={href}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>{children}</a>
    </NextLink>
  )
}
