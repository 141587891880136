// libs
import { tw } from 'lib/tw'

export const LinkTitle = tw.span<{ $isActive: boolean }>`${({ $isActive }) =>
  $isActive ? 'text-white' : 'text-gray-300 group-hover:text-white'}
    visible ml-3 text-sm font-medium select-none
`

export const LinkContainer = tw.div<{ $isActive: boolean; $isSubItem?: boolean }>`${({
  $isActive,
}) => ($isActive ? 'bg-gray-900' : 'hover:bg-gray-700 hover:text-white')}

  ${({ $isSubItem }) => ($isSubItem ? 'pl-12 pr-4' : 'px-4')}
    flex items-center whitespace-nowrap rounded-md py-2 group cursor-pointer
`

export const Bubble = tw.span<{ $isActive: boolean }>`${({ $isActive }) =>
  $isActive ? 'bg-gray-800 text-white' : 'bg-gray-900 text-gray-300 group-hover:text-white'}
  inline-block rounded-full py-0.5 px-3 text-xs font-medium group-hover:bg-gray-800 ml-auto
`

export const IconWrapper = tw.div<{ $expanded: boolean }>`${({ $expanded }) =>
  $expanded ? 'rotate-90' : 'rotate-0'}
  ml-auto cursor-pointer
`
