// libs
import Image from 'next/image'
import { tw } from 'packages/lib/tw'

interface Props {
  src?: string
  name: string
  preset?: AvatarPresets
}

export const avatarPresets = {
  large: { px: 48, class: 'h-12 w-12' },
  medium: { px: 40, class: 'h-9 w-9' },
  small: { px: 32, class: 'h-8 w-8' },
}

type AvatarPresets = keyof typeof avatarPresets

const AvatarContainer = tw<{ preset: AvatarPresets }>('div', { displayName: 'Avatar' })`
  ${({ preset }) => `${avatarPresets[preset].class}`}
  flex items-center justify-center rounded-full bg-gray-200 text-sm ring-2 ring-white select-none
`

export function Avatar({ src, name, preset = 'medium' }: Props): JSX.Element {
  return (
    <AvatarContainer preset={preset}>
      {src ? (
        <Image
          src={src}
          alt="avatar"
          width={avatarPresets[preset].px}
          height={avatarPresets[preset].px}
          className="rounded-full"
        />
      ) : (
        <span>{name.toUpperCase()}</span>
      )}
    </AvatarContainer>
  )
}
