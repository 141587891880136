// components
import { MenuItem } from './Main'
import { MenuItemExpandable } from './Expandable'
// types
import { NavItem } from './MenuItem.types'

interface SidebarMenuItemProps {
  item: NavItem
}

export function SidebarMenuItem({ item }: SidebarMenuItemProps): JSX.Element {
  return item.subItems ? <MenuItemExpandable item={item} /> : <MenuItem item={item} />
}
