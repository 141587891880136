// libs
import { tw } from 'packages/lib/tw'
import Image from 'next/image'

export const LayoutContainer = tw.div`flex min-h-screen`

export const FormContainer = tw.div`
  flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24
`

export const FormInnerContainer = tw.div`mx-auto w-full max-w-sm lg:w-96`

export const FormTitle = tw.h2`mt-6 mb-8 text-3xl font-extrabold text-gray-900`

export const FormDescription = tw.h1`mb-8 text-sm text-gray-500`

export const BackgroundWrapper = tw.div`relative hidden w-0 flex-1 lg:block`

export const BackgroundImg = tw(Image)`absolute inset-0 h-full w-full object-cover`
