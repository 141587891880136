// libs
import Head from 'next/head'
import { tw } from 'lib/tw'
import { ReactNode } from 'react'
// components
import { Footer } from 'ui/Footer'
import { Header } from 'ui/Header'

// views
const Content = tw.div`mb-auto mt-[98px] md-down:mt-[71px]`

// types
type PublicLayoutProps = {
  title?: string
  children: ReactNode
}

export function PublicLayout({ title, children }: PublicLayoutProps): JSX.Element {
  return (
    <>
      <Head>
        <title>{title || 'Snap - Automated workflow solutions for the Proptech industry'}</title>
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
      </Head>

      <Header className="fixed w-full" />
      <Content>{children}</Content>
      <Footer />
    </>
  )
}
