// libs
import { tw } from 'lib/tw'

export const HeaderContainer = tw.header`flex flex-col border-b border-gray-200`

export const HeaderWrapper = tw.div`
  flex h-16 justify-between items-center bg-white px-2 sm:px-4 lg:px-8 py-1 lg:py-3
`

export const IconWrapper = tw.div`
  flex items-center justify-center lg:hidden text-gray-400 hover:text-gray-500 hover:bg-gray-100 p-2
  rounded-md ml-4 cursor-pointer
`

export const ContentWrapper = tw.div`hidden items-center gap-10 md:flex`

export const LinkTitle = tw.span`cursor-pointer text-sm font-medium text-gray-900 p-2 md:p-3`

export const LogoContainer = tw.span`ml-4 mr-4 w-[22px] overflow-hidden md:ml-0 md:w-[94px]`

export const SearchbarContainer = tw.span`flex flex-1 justify-center xl:justify-end`

export const HeaderNavigation = tw.div`mr-8 hidden space-x-4 lg:block`

export const ItemsContent = tw.div`hidden items-center space-x-2 lg:flex ml-4`
